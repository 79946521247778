import React, { useState, useEffect} from "react";

export const Navigation = (props) => {

  const [scrolled, setScrolled] = useState(false);
  const [navbarClasses, setNavbarClasses] = useState('navbar navbar-default navbar-fixed-top');

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Example of conditional className based on scroll state
  if (scrolled) {
    if (navbarClasses === 'navbar navbar-default navbar-fixed-top'){
      setNavbarClasses('navbar navbar-default navbar-fixed-top navbar-scrolled');
    }
  } else {
    if (navbarClasses === 'navbar navbar-default navbar-fixed-top navbar-scrolled'){
      setNavbarClasses('navbar navbar-default navbar-fixed-top');
    }
  }

  return (
    <nav id="menu" className={navbarClasses}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            RenegAI
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About Us
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Contact Us
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>*/}
          </ul>
        </div>
      </div>
    </nav>
  );
};
